import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { CardElement, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import axios from "../../utils/requests";

const CheckoutContext = createContext(null);

const initialState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  },
  shipping_address: {
    shipping_first_name: "",
    shipping_last_name: "",
    shipping_line1: "",
    shipping_line2: "",
    shipping_city: "",
    shipping_region: "",
    shipping_country: "",
    shipping_postalcode: "",
  },
  billing_address: {
    billing_first_name: "",
    billing_last_name: "",
    billing_line1: "",
    billing_line2: "",
    billing_city: "",
    billing_region: "",
    billing_country: "",
    billing_postalcode: "",
  },
  organization: {},
  feeModel: {},
  amount: null,
  completed: false,
  loading: false,
  errors: {},
  autoPay: false,
  saveBillingInfo: false,
  transaction_id: null,
  order_id: null,
  paymentMethod: null,
  subscription_id: null,
  billingSameAsShipping: true,
  shippingOption: {},
  cart: {},
  formFields: [],
  formSelectOptions: [],
  formValues: {},
  orderSummary: {
    subtotal: null,
    shipping_cost: null,
    fees: null,
    total: null,
  },
};

export const CheckoutFormProvider = (props) => {
  // added  the form state
  const state = {
    ...initialState,
    formFields: props.formFields,
    formSelectOptions: props.formSelectOptions,
    organization: props.organization,
    paymentMethods: props.paymentMethods,
    cart: props.cart,
    feeModel: props.feeModel,
    shippingOptions: props.shippingOptions || [],
  };

  if (props.shippingOptions?.length > 0) {
    state.shippingOption = props.shippingOptions[0];
  }

  if (props.paymentMethods?.data?.length > 0) {
    state.paymentMethod = props.paymentMethods[0].id;
  }

  // props.currentUser ? (state.user = props.currentUser) : null;
  if (props.currentUser) {
    state.user = props.currentUser;
    state.billing_address = {
      billing_first_name: props.currentUser.first_name,
      billing_last_name: props.currentUser.last_name,
      billing_line1: props.currentUser.line1,
      billing_line2: props.currentUser.line2,
      billing_city: props.currentUser.city,
      billing_region: props.currentUser.region,
      billing_country: props.currentUser.country,
      billing_postalcode: props.currentUser.postalcode,
    };
    state.shipping_address = {
      shipping_first_name: props.currentUser.first_name,
      shipping_last_name: props.currentUser.last_name,
      shipping_line1: props.currentUser.line1,
      shipping_line2: props.currentUser.line2,
      shipping_city: props.currentUser.city,
      shipping_region: props.currentUser.region,
      shipping_country: props.currentUser.country,
      shipping_postalcode: props.currentUser.postalcode,
    };
  } else {
    null
  }

  const [store, dispatch] = useReducer(checkoutReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <CheckoutContext.Provider value={value} {...props} />;
};

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, user: action.user };
    case "TOGGLE_LOADING":
      return { ...state, loading: !state.loading };
    case "TOGGLE_COMPLETED":
      return { ...state, completed: !state.completed };
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SET_ERRORS":
      return { ...state, errors: action.errors };
    case "SET_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.paymentMethod };
    case "SET_ORDER_ID":
      return { ...state, order_id: action.order_id };
    case "SET_TRANSACTION_ID":
      return { ...state, transaction_id: action.transaction_id };
    case "SET_SHIPPING_OPTION":
      return { ...state, shippingOption: action.shippingOption };
    case "UPDATE_SAVE_BILLING_INFO":
      return { ...state, saveBillingInfo: action.saveBillingInfo };
    case "TOGGLE_SAME_AS_BILLING":
      return { 
        ...state, 
        billingSameAsShipping: action.billingSameAsShipping, 
        shipping_address: action.billingSameAsShipping ? 
        { 
          shipping_first_name: state.billing_address.billing_first_name,
          shipping_last_name: state.billing_address.billing_last_name,
          shipping_line1: state.billing_address.billing_line1,
          shipping_line2: state.billing_address.billing_line2,
          shipping_city: state.billing_address.billing_city,
          shipping_region: state.billing_address.billing_region,
          shipping_country: state.billing_address.billing_country,
          shipping_postalcode: state.billing_address.billing_postalcode,
        } : { 
          shipping_first_name: "",
          shipping_last_name: "",
          shipping_line1: "",
          shipping_line2: "",
          shipping_city: "",
          shipping_region: "",
          shipping_country: "",
          shipping_postalcode: "",
         } 
      };
    case "UPDATE_FORM_VALUE":
      return { ...state, formValues: { ...state.formValues, [action.formFieldId]: action.value } };
    case "COPY_BILLING_TO_SHIPPING":
      return {
        ...state,
        shipping_address: {
          shipping_first_name: state.billing_address.billing_first_name,
          shipping_last_name: state.billing_address.billing_last_name,
          shipping_line1: state.billing_address.billing_line1,
          shipping_line2: state.billing_address.billing_line2,
          shipping_city: state.billing_address.billing_city,
          shipping_region: state.billing_address.billing_region,
          shipping_country: state.billing_address.billing_country,
          shipping_postalcode: state.billing_address.billing_postalcode,
        },
      };
    case "SET_ORDER_SUMMARY":
      return { ...state, orderSummary: action.orderSummary };
    case "UPDATE_CART":
      return { ...state, cart: action.cart };
    case "UPDATE_SHIPPING_ADDRESS":
      return { ...state, shipping_address: action.shipping_address };
    case "UPDATE_BILLING_ADDRESS":
      return { 
        ...state,
        billing_address: action.billing_address,
        user: {
          ...state.user,
          first_name: action.billing_address.billing_first_name,
          last_name: action.billing_address.billing_last_name,
        },
      };
    case "UPDATE_TOTAL_AMOUNT":
      return { ...state, amount: action.amount };
    default:
      return state;
  }
};

export const useCheckout = () => {
  const [state, dispatch] = useContext(CheckoutContext);

  const updateUserAttribute = useCallback(
    (attribute, value) => {
      const user = { ...state.user };
      user[attribute] = value;

      if (attribute === "password") user["password_confirmation"] = value;

      dispatch({ type: "UPDATE_USER", user });

    },
    [state, dispatch]
  );

  const setPaymentMethod = useCallback(
    (method) => {
      dispatch({ type: "SET_PAYMENT_METHOD", paymentMethod: method.id });
    },
    [state, dispatch]
  );

  const toggleSaveBillingInfo = () => {
    dispatch({
      type: "UPDATE_SAVE_BILLING_INFO",
      saveBillingInfo: !state.saveBillingInfo,
    });
  };

  const toggleSameAsBilling = () => {
    dispatch({ type: "TOGGLE_SAME_AS_BILLING", billingSameAsShipping: !state.billingSameAsShipping });
  };

  const setShippingAddress = useCallback(
    (attribute, value) => {
      const shippingInfo = { ...state.shipping_address };
      shippingInfo[attribute] = value;

      dispatch({ type: "UPDATE_SHIPPING_ADDRESS", shipping_address: shippingInfo });
    },
    [state, dispatch]
  );

  const setBillingAddress = useCallback(
    (attribute, value) => {
      const billingInfo = { ...state.billing_address };
      billingInfo[attribute] = value;

      dispatch({ type: "UPDATE_BILLING_ADDRESS", billing_address: billingInfo });
      
      if (state.billingSameAsShipping) {
        dispatch({ type: "COPY_BILLING_TO_SHIPPING" });
      }
    },
    [state, dispatch]
  );

  const setShippingOption = useCallback(
    (method) => {
      dispatch({ type: "SET_SHIPPING_OPTION", shippingOption: method });
    },
    [state, dispatch]
  );

  const setOrderSummary = useCallback(
    (method) => {
      dispatch({ type: "SET_ORDER_SUMMARY", orderSummary: method });
      dispatch({ type: "UPDATE_TOTAL_AMOUNT", amount: method.total });
    },
    [state, dispatch]
  );

  const updateCartQuantity = useCallback(async (pvId, quantity) => {
    try {
      const response = await axios.post(`/o/${state.organization.slug}/add_to_cart?product_variant_id=${pvId}&product_variant_qty=${quantity}`, {
        product_variant_id: pvId,
        quantity: quantity,
      });
      const newCart = { ...state.cart };
      newCart.quantity[pvId] = quantity;
      dispatch({ type: "UPDATE_CART", cart: newCart });
    } catch (error) {
      console.error("Error updating cart item quantity:", error); // Change to UI notification
    }
  }, [state, dispatch]);

  const removeCartItem = useCallback(async (pvId, quantity) => {
    try {
      const response = await axios.post(`/o/${state.organization.slug}/remove_from_cart?product_variant_id=${pvId}&product_variant_qty=${quantity}`, {
        product_variant_id: pvId,
        quantity: quantity,
      });
      const newCart = { ...state.cart };
      delete newCart.quantity[pvId];
      newCart.productVariants = newCart.productVariants.filter(pv => pv.id !== pvId);

      dispatch({ type: "UPDATE_CART", cart: newCart });
    } catch (error) {
      console.error("Error removing cart item:", error);
    }
  }, [state, dispatch]);

  const shippingEnabled = useMemo(() => {
    if (state.shippingOptions.length > 0) {
      return state.cart.productVariants.some(
        (pv) => pv.product.shipping_enabled);
    }
    return false;
  }, [state.cart.productVariants]);

  useEffect(() => {
    if (!shippingEnabled && state.shippingOption != null) {
      dispatch({ type: "SET_SHIPPING_OPTION", shippingOption: null });
    }
  }, [shippingEnabled, state.shippingOption]);

  return {
    state,
    dispatch,
    stripe: useStripe(),
    elements: useElements(),
    toggleSaveBillingInfo,
    updateUserAttribute,
    setPaymentMethod,
    toggleSameAsBilling,
    setShippingOption,
    setOrderSummary,
    updateCartQuantity,
    removeCartItem,
    setShippingAddress,
    setBillingAddress,
    shippingEnabled,
  };
};

// this is the function that handles form submission of single charges
export const singleChargeSubmit = async ({
  state,
  dispatch,
  stripe,
  elements,
}) => {
  const {
    user,
    organization,
    transaction_id,
    order_id,
    saveBillingInfo,
    paymentMethod,
    shipping_address,
    billing_address,
    shippingOption,
    formValues
  } = state;

  dispatch({ type: "TOGGLE_LOADING" });

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    dispatch({ type: "TOGGLE_LOADING" });
    return;
  }

  let formValuesAttributes = Object.entries(formValues).map(([key, value]) => ({
    form_field_id: key,
    value: value,
  }));

  let params = {
    transaction_id,
    order_id,
    user_attributes: user,
    receipt_email: user.email,
    email: user.email,
    phone: user.phone,
    first_name: billing_address.billing_first_name,
    last_name: billing_address.billing_last_name,
    organization_id: organization.id,
    membership_id: user.membership_id,
    save_billing_info: saveBillingInfo,
    payment_method: paymentMethod,
    shipping_attributes: shipping_address,
    billing_attributes: billing_address,
    shipping_option_id: shippingOption?.id || null,
    shipping_cost: shippingOption?.cost_cents || 0,
    form_values_attributes: formValuesAttributes,
  };


  // Create payment method
  // TODO PULL this into a new method,
  // only create a payment method if one is not set in the stage.
  // if a payment method is already in the state just use it. and
  // skip this method
  if (!paymentMethod) {
    const paymentMethodResponse = await createPaymentMethod({
      stripe,
      elements,
      organization,
      user,
      billing_address,
    });

    // return early and show errors if payment method doesnt create on stripe
    if (paymentMethodResponse.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [paymentMethodResponse.error.message] },
      });
      return;
    }

    // set payment method id on the state so we dont do it again.
    dispatch({
      type: "SET_PAYMENT_METHOD",
      paymentMethod: paymentMethodResponse.paymentMethod.id,
    });
    params.payment_method = paymentMethodResponse.paymentMethod.id;
  }

  try {
    const response = await axios.post(`/o/${organization.id}/store/checkout`, {
      ...params,
    });

    dispatch({
      type: "SET_TRANSACTION_ID",
      transaction_id: response.data.transaction.id,
    });

    dispatch({
      type: "SET_ORDER_ID",
      order_id: response.data.order.id,
    });

    const result = await stripe.confirmCardPayment(
      response.data.stripe_intent.client_secret,
      { payment_method: params.payment_method }
    );

    if (result.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [result.error.message] },
      });
    } else {
      if (result.paymentIntent.status === "succeeded") {
        // what do we do on the success of a card transaction
        window.location = `/o/${organization.slug}/store/checkout/receipt/?order_id=${response.data.order.id}`;
        dispatch({ type: "TOGGLE_COMPLETED" });
        dispatch({ type: "TOGGLE_LOADING" });
      }
    }
  } catch (error) {

    dispatch({ type: "TOGGLE_LOADING" });
    dispatch({ type: "SET_ERRORS", errors: error.response.data.errors });

    if (error.response.data.transaction_id) {
      dispatch({
        type: "SET_TRANSACTION_ID",
        transaction_id: error.response.data.transaction_id,
      });
    }
    if (error.response.data.order_id) {
      dispatch({
        type: "SET_ORDER_ID",
        order_id: error.response.data.order_id,
      });
    }
  }
};

const createPaymentMethod = async ({
  stripe,
  elements,
  organization,
  user,
  billing_address,
}) => {
  return await stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    metadata: {
      organization_id: organization.id,
    },
    billing_details: {
      name: `${billing_address.billing_first_name} ${billing_address.billing_last_name}`,
      email: user.email,
      phone: user.phone && user.phone.length > 0 ? user.phone : null,
      address: {
        line1: billing_address.line1 && billing_address.line1.length > 0 ? billing_address.line1 : null,
        line2: billing_address.line2 && billing_address.line2.length > 0 ? billing_address.line2 : null,
        city: billing_address.city && billing_address.city.length > 0 ? billing_address.city : null,
        state: billing_address.region && billing_address.region.length > 0 ? billing_address.region : null,
        country: billing_address.country && billing_address.country.length > 0 ? billing_address.country : null,
        postal_code:
          billing_address.postalcode && billing_address.postalcode.length > 0
            ? billing_address.postalcode
            : null,
      },
    },
    // },
  });
};
