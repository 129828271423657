import React from "react";
import { Input } from "./FormInputs";
import FormValuesFields from "../FormValuesFields";
import { COUNTRY_SELECT_OPTIONS } from "../../utils/countrySelectOptions";
import { StripeSDKLoader } from "../../utils/StripeSdkLoader";
import {
  CheckoutFormProvider,
  useCheckout,
  singleChargeSubmit,
} from "./StateManagement";
import { PaymentMethodSection } from "./PaymentComponents";
import { ShippingOptionSection } from "./ShippingComponents";
import { OrderSummarySection } from "./OrderSummaryComponents";

const MainForm = () => {
  const { state, dispatch, stripe, elements, shippingEnabled } = useCheckout();
  const handleSubmit = async (event) => {
    event.preventDefault();
    singleChargeSubmit({ state, dispatch, stripe, elements });
  };

  const { loading } = state;

  return (
    <form className="lg:w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col lg:flex-row lg:space-x-24">

        <div className="lg:w-1/2 order-none lg:order-first">
          <div className="mt-8">
            <h4 className="text-lg font-medium text-gray-900">Payment Info</h4>
            <div className="mt-6 ">
              <PaymentMethodSection />
            </div>
          </div>
        

          <div className="mt-8 border-t border-gray-300 pt-10">
            <h4 className="text-lg font-medium text-gray-900">Contact information</h4>
            {/* <Input
              type="text"
              label="First Name"
              name="first_name"
              isRequired={true}
              context="user"
            />
            <Input
              type="text"
              label="Last Name"
              name="last_name"
              isRequired={true}
              context="user"
            />
            <Input
              type="phone"
              label="Phone number"
              name="phone"
              isRequired={true}
              context="user"
            /> */}
            <label className="block flex-1">
              <Input type="email" label="Email" name="email" isRequired={true} context="user" />
            </label>
          </div>

          {(state.formFields?.length > 0) && (
            <FormValuesFields
              formFields={state.formFields}
              formSelectOptions={state.formSelectOptions}
              attribute="form_values_attributes"
              state={state}
              dispatch={dispatch}
            />
          )}

          <div className="mt-8 border-t border-gray-300 pt-10">
            <h4 className="text-lg font-medium text-gray-900">Billing Information</h4>
            <BillingFields require_address_fields={false} />
          </div>

          {shippingEnabled && (
            <div className="mt-8 border-t border-gray-300 pt-10">
              <div className="">
                <ShippingOptionSection />
              </div>
            </div>
          )}   

          {shippingEnabled && state.shippingOption.cost_cents !== 0 && (
            <div className="mt-8 border-t border-gray-300 pt-10">
              <h4 className="text-lg font-medium text-gray-900">Shipping Information</h4>
                <Input
                  type="checkbox"
                  label="Same as billing?"
                  name="same_as_billing"
                  isRequired={false}
                  isChecked={state.billingSameAsShipping}
                />
                {!state.billingSameAsShipping && (
                  <div>
                    <ShippingFields require_address_fields={true} />
                  </div>
                )}
            </div>
          )}


        <div className="flex items-center justify-end mt-8 border-t border-gray-300 pt-10">
          <button
            disabled={loading}
            className="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
          >
            {loading ? <span> Placing Order </span> : <span>Make Payment</span>}
            {/* <svg
              className="h-5 w-5 mx-2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
            </svg> */}
          </button>
        </div>   

        </div>
          <div className="lg:w-1/2 mt-8 order-first lg:order-none">
                  <OrderSummarySection />
          </div>
      </div>
    </form>
  );
};

const ShippingFields = ({ require_address_fields }) => {
  return (
    <>
      <Input
        type="text"
        label="First Name"
        name="shipping_first_name"
        isRequired={true}
        context="shipping"
      />

      <Input
        type="text"
        label="Last Name"
        name="shipping_last_name"
        isRequired={true}
        context="shipping"
      />

      <Input
        type="text"
        label="Address"
        name="shipping_line1"
        isRequired={require_address_fields}
        context="shipping"
      />

      <Input
        type="text"
        label="Apartment, suite, etc"
        name="shipping_line2"
        isRequired={false}
        context="shipping"
      />

      <Input
        type="text"
        label="City"
        name="shipping_city"
        isRequired={require_address_fields}
        context="shipping"
      />

      <Input
        type="text"
        label="State / Provence"
        name="shipping_region"
        isRequired={require_address_fields}
        context="shipping"
      />

      <Input
        type="dropdown"
        label="Country"
        name="shipping_country"
        isRequired={require_address_fields}
        selectOptions={COUNTRY_SELECT_OPTIONS}
        context="shipping"
      />

      <Input
        type="text"
        label="ZIP / postal code"
        name="shipping_postalcode"
        isRequired={require_address_fields}
        context="shipping"
      />
    </>
  );
};

const BillingFields = ({ require_address_fields }) => {
  return (
    <>
      <Input
        type="text"
        label="First Name"
        name="billing_first_name"
        isRequired={true}
        context="billing"
      />

      <Input
        type="text"
        label="Last Name"
        name="billing_last_name"
        isRequired={true}
        context="billing"
      />

      <Input
        type="text"
        label="Address"
        name="billing_line1"
        isRequired={require_address_fields}
        context="billing"
      />

      <Input
        type="text"
        label="Apartment, suite, etc"
        name="billing_line2"
        isRequired={false}
        context="billing"
      />

      <Input
        type="text"
        label="City"
        name="billing_city"
        isRequired={require_address_fields}
        context="billing"
      />

      <Input
        type="text"
        label="State / Provence"
        name="billing_region"
        isRequired={require_address_fields}
        context="billing"
      />

      <Input
        type="dropdown"
        label="Country"
        name="billing_country"
        isRequired={require_address_fields}
        selectOptions={COUNTRY_SELECT_OPTIONS}
        context="billing"
      />

      <Input
        type="text"
        label="ZIP / postal code"
        name="billing_postalcode"
        isRequired={require_address_fields}
        context="billing"
      />
    </>
  );
};

const StoreCheckoutForm = (props) => {
  const {
    stripe_publishable_key,
    organization,
    current_user,
    payment_methods,
    shipping_options,
    cart,
    fee_model,
    form_fields,
    form_select_options,
  } = props;

  return (
    <StripeSDKLoader stripe_publishable_key={stripe_publishable_key}>
      <CheckoutFormProvider
        organization={organization}
        paymentMethods={payment_methods}
        currentUser={current_user}
        shippingOptions={shipping_options}
        shippingOption={shipping_options}
        cart={cart}
        feeModel={fee_model}
        formFields={form_fields}
        formSelectOptions={form_select_options}
      >
        <MainForm />
      </CheckoutFormProvider>
    </StripeSDKLoader>
  );
};

export default StoreCheckoutForm;
